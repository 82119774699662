import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import BusinessPage from '../components/business-page'

import Footer from '../components/footer'

export default () => {
    return (
        <Layout>
            <SEO title={'All your business cards in one place'} />
            <BusinessPage />
            <Footer />
        </Layout>
    )
}
