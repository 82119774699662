import React, { useState, useContext, createContext } from 'react'

import styled from 'styled-components'

import VerificoLogoImage from '../images/logo-white.svg'

import Form from './form'

import Input from './input'

import CardList from './card-list'

const RouterContext = createContext({})

const VerificoLogo = styled(VerificoLogoImage)`
    height: 20px;
`

const Header = styled.header`
    background-color: ${props => props.theme.gray1};
    padding: 8rem 0;
    color: white;
    display: flex;
    font-weight: 800;
    justify-content: center;
`

const Sep = styled.div`
    background-color: white;
    width: 1px;
    margin: 0 1rem;
`

const Router = ({ defaultRoute, children }) => {
    const [currentRoute, setCurrentRoute] = useState(defaultRoute)

    return (
        <RouterContext.Provider value={{ currentRoute, setCurrentRoute }}>
            {children({ currentRoute, setCurrentRoute })}
        </RouterContext.Provider>
    )
}

const Route = ({ name, children }) => {
    const { currentRoute } = useContext(RouterContext)
    return name === currentRoute && children
}

export default () => {
    const [filter, setFilter] = useState(null)

    return (
        <>
            <Header>
                <VerificoLogo />
                <Sep />
                All your varified business <br />
                cards in one place
            </Header>
            <Form>
                <Input
                    name="search"
                    placeholder="name, email or mobile"
                    type="search"
                    onChange={e => setFilter(e.target.value)}
                />
            </Form>

            <Router defaultRoute="dashboard">
                {({ currentRoute, setCurrentRoute }) => (
                    <>
                        <Route name="dashboard">
                            <CardList filter={filter} />
                        </Route>
                        <Route name="card-list">
                            <div>card-list</div>
                        </Route>
                        <Route name="add-business-card">
                            <div>add-business-card</div>
                        </Route>
                    </>
                )}
            </Router>
        </>
    )
}
