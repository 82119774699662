import React, { useState, useContext, createContext } from 'react'

import styled from 'styled-components'

import { Input } from './styles'

import { GOLDEN_I, GOLDEN } from './styles'

import { useTrail, animated } from 'react-spring'

import Button from './button'

const FormContext = createContext({})

const Form = styled.form`
    padding: ${props => (props.noPadding ? 0 : 1)}rem;
    margin-top: ${props => (props.noMarginTop ? 0 : -5)}rem;
    position: relative;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
`

export const LabelSmallText = styled.span`
    font-size: ${GOLDEN_I}em;
    font-weight: 500;
    margin-left: ${props => (props.marginLeft ? '.5rem' : 0)};
    color: ${props => props.theme.gray1};
`
export const FormCard = styled.div`
    background-color: white;
    padding: ${GOLDEN_I}rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;

    position: relative;
    transition: box-shadow ease 0.3s;
`
export const CardTitle = styled.label`
    margin-top: 0.5rem;
    display: block;
    margin-bottom: ${GOLDEN * 0.5}rem;
    font-weight: bold;
    font-size: 1.5rem;
`

const config = { mass: 5, tension: 1500, friction: 200 }
const Step = ({ active, children }) => {
    const trail = useTrail(children.length, {
        config,
        opacity: active ? 1 : 0,
        x: active ? 0 : -20,
        maxHeight: active ? 1000 : 0,
        from: { opacity: 0, x: -20, maxHeight: 0, overflow: 'hidden' },
    })

    return trail.map(({ x, height, ...rest }, index) => (
        <animated.div
            key={index}
            style={{
                ...rest,
                transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                pointerEvents: active ? 'initial' : 'none',
            }}
        >
            <animated.div style={{ height }}>{children[index]}</animated.div>
        </animated.div>
    ))
}

function onSubmit(e) {
    e.preventDefault()
}

export function InputGroup({
    label,
    name,
    type,
    placeholder,
    children,
    noMarginBottom,
    ...props
}) {
    const { focusedCard, setFocusedCard, loading } = useContext(FormContext)
    return (
        <FormCard focus={focusedCard === name} {...props}>
            <CardTitle htmlFor={name}>{label}</CardTitle>
            <Input
                id={name}
                type={type}
                placeholder={placeholder}
                noMarginBottom={noMarginBottom}
                onFocus={() => setFocusedCard(name)}
                onBlur={() => setFocusedCard('none')}
                disabled={loading}
            />
            {children}
        </FormCard>
    )
}

export default ({
    buttonLabel,
    lastButtonLabel,
    steps,
    children,
    ...props
}) => {
    const [activeStep, setActiveStep] = useState(1)
    const [focusedCard, setFocusedCard] = useState('')
    const [loading, setLoading] = useState(false)

    const goToNextStep = () => {
        setLoading(true)
        setTimeout(function() {
            setActiveStep(activeStep =>
                activeStep + 1 > steps.length ? 1 : activeStep + 1
            )
            setLoading(false)
        }, 1000)
    }
    return (
        <FormContext.Provider
            value={{
                focusedCard,
                setFocusedCard,
                loading,
            }}
        >
            <Form onSubmit={onSubmit} {...props} autocomplete="off">
                {steps &&
                    steps.map((elements, i) => (
                        <Step active={activeStep === i + 1} key={i}>
                            {elements}
                        </Step>
                    ))}
                {steps && (
                    <Button onClick={() => goToNextStep()} loading={loading}>
                        {loading
                            ? 'Saving...'
                            : activeStep === steps.length
                            ? buttonLabel
                            : lastButtonLabel}
                    </Button>
                )}
                {!steps && children}
            </Form>
        </FormContext.Provider>
    )
}
