import React from 'react'

import styled from 'styled-components'

import faker from 'faker'

import isEmpty from 'lodash-es/isEmpty'

const companyName = faker.company.companyName()

const Card = styled.div`
    background-color: white;
    padding: 0.5rem;
    margin: 0.5rem;
`

const cards = Array.from({ length: 100 }).map((_, id) => {
    const firstName = faker.name.firstName()
    return {
        id,
        name: firstName + ' ' + faker.name.lastName(),
        email:
            firstName.toLowerCase() +
            '@' +
            companyName.replace(/[, &]/g, '').toLowerCase() +
            '.com',
        companyName,
        mobile: faker.phone.phoneNumber(),
    }
})

function filterCards(cards, filter) {
    if (isEmpty(filter)) return cards
    return cards.filter(card => {
        let matched = false

        for (let cardKey of Object.keys(card)) {
            if (typeof card[cardKey] === 'string')
                try {
                    matched = matched || card[cardKey].match(new RegExp(filter))
                } catch (e) {
                    return false
                }
        }

        return matched
    })
}

export default ({ filter }) => {
    const filteredCards = filterCards(cards, filter)
    return (
        <>
            {filteredCards.length ? (
                filteredCards.map(c => (
                    <Card key={c.id}>
                        <div>{c.name}</div>
                        <div>{c.email}</div>
                        <div>{c.companyName}</div>
                        <div>{c.mobile}</div>
                    </Card>
                ))
            ) : (
                <Card>No records found</Card>
            )}
        </>
    )
}
